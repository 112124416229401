<template>
  <ftx-dialog
    dialog="QuickProductDetailDialog"
    :position="isMobile ? 'right' : 'bottom'"
    maximized
  >
    <template v-slot:DialogContent>
      <q-card class="quickDialogProduct">
        <div class="dialogHeader">
          <q-btn class="close" flat dense v-close-popup v-if="!isMobile">
            <SvgIcon icon="close" />
          </q-btn>
          <div class="container">
            <div class="row no-wrap">
              <div class="col">
                <h4 class="text-h5 text-black q-mt-none q-mb-md">
                  <q-btn v-if="isMobile" flat dense>
                    <SvgIcon
                      color="white"
                      className="q-mr-xs"
                      size="1rem"
                      icon="back-arrow-line"
                      v-close-popup
                    />
                  </q-btn>
                  Quick Shop
                </h4>
              </div>
            </div>
          </div>
        </div>
        <q-scroll-area
          :thumb-style="scrollAreaThumbStyle"
          :bar-style="scrollAreaBarStyle"
          :visible="scrollAreaVisible"
          :style="
            isMobile
              ? 'height:calc(100dvh - 62px);max-width: 100%;'
              : 'height:calc(90vh - 130px);max-width: 100%;'
          "
        >
          <div class="container">
            <!-- <div class="q-pt-md"><LastOrderedInfo /></div> -->
            <ProductDetail
              :productDetail="quickProduct"
              :addToCartButton="quickProductCartButtonType"
              :isDialog="true"
            />
            <!-- <div class="q-pt-md q-pb-xl"><ProductOfferInfo /></div> -->
          </div>
        </q-scroll-area>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
export default {
  components: {
    ProductDetail: () => import('components/detail/ProductDetail'),
    // LastOrderedInfo: () => import('components/detail/LastOrderedInfo'),
    // ProductOfferInfo: () => import('components/detail/ProductOfferInfo'),
  },
  computed: {
    properties() {
      return this.getDialogProperties('QuickProductDetailDialog')
    },
    quickProduct() {
      return this.properties.quickProduct
    },
    quickProductCartButtonType() {
      return this.properties.cartBtnType
    },
  },
}
</script>
<style lang="scss">
.quickDialogProduct {
  width: 100vw !important;
  padding: 0 0 30px;
  position: relative;
  @media (max-width: 1023px) {
    padding-bottom: 0;
  }
  .dialogHeader {
    padding: 30px 0 30px;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      font-size: 8px;
    }
    h4.text-h5 {
      margin: 0;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    @media (max-width: 599px) {
      padding: 15px 0;
      background: $primary;
      background: var(--q-color-primary) !important;
      color: $white;
      position: relative;
      z-index: 9;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      h4.text-h5 {
        padding-right: 60px;
        font-size: 18px;
        color: #fff !important;
        text-align: left;
      }
      .close {
        width: 70px;
        height: 70px;
      }
    }
  }
  .container {
    max-width: 960px;
    @media (min-width: 1440px) {
      max-width: 1140px;
    }
  }
  .productImageLarge {
    margin-top: 15px;
  }
}
</style>
